const csvConfig = {
  colParser: {
    Last: "string",
    First: "string",
    PA: "number",
    AB: "number",
    H: "number",
    "1B": "number",
    "2B": "number",
    "3B": "number",
    HR: "number",
    RBI: "number",
    R: "number",
    HBP: "number",
    ROE: "number",
    FC: "number",
    CI: "number",
    BB: "number",
    SO: "number",
    SB: "number",
    TB: "number",
    SAC: "number",
    SF: "number",
    "2OUTRBI": "number",
    Number: "omit",
    GP: "omit",
    AVG: "omit",
    OBP: "omit",
    SLG: "omit",
    OPS: "omit",
    "PA/BB": "omit",
    "BB/K": "omit",
    "C%": "omit",
    "K-L": "omit",
    CS: "omit",
    "SB%": "omit",
    PIK: "omit",
    GIDP: "omit",
    GITP: "omit",
    XBH: "omit",
    "AB/HR": "omit",
    "BA/RISP": "omit",
    PS: "omit",
    "PS/PA": "omit",
    "2S+3": "omit",
    "2S+3%": "omit",
    "6+": "omit",
    "6+%": "omit",
    "FLB%": "omit",
    "GB%": "omit",
    LOB: "omit",
    HHB: "omit",
    QAB: "omit",
    "QAB%": "omit",
    BABIP: "omit",
  },
};

export default csvConfig;
