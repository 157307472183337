import { useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "react-query";
import csv from "csvtojson";
import { getPlayers, sendGameCSVData } from "../api/api";
import csvConfig from "../config/csvConfig";
import { PLAYERS, RECENT_OPPONENTS } from "../constants/queryKeys";
import { cleanCSV, findPlayerIdByName, getBPA } from "../utils/utils";

const useAddGame = (opts = {}) => {
  const queryClient = useQueryClient();
  const toast = useToast();

  return useMutation(
    async ({ fileContents, opponent, title, date }) => {
      const players = queryClient.getQueryData(PLAYERS) || (await getPlayers());
      if (!players) throw new Error("Players not fetched");

      const content = cleanCSV(fileContents);
      const json = await csv(csvConfig).fromString(content);
      const stats = json.reduce((acc, row) => {
        if (row.First && row.Last) {
          acc.push({
            ...row,
            playerId: players.find(findPlayerIdByName(row))?.id,
            BPA: getBPA(row),
            PA: row.PA || 0,
            AB: row.AB || 0,
            H: row.H || 0,
            "1B": row["1B"] || 0,
            "2B": row["2B"] || 0,
            "3B": row["3B"] || 0,
            HR: row.HR || 0,
            RBI: row.RBI || 0,
            R: row.R || 0,
            HBP: row.HBP || 0,
            ROE: row.ROE || 0,
            FC: row.FC || 0,
            CI: row.CI || 0,
            BB: row.BB || 0,
            SO: row.SO || 0,
            SB: row.SB || 0,
            TB: row.TB || 0,
            SAC: row.SAC || 0,
            SF: row.SF || 0,
            "2OUTRBI": row["2OUTRBI"] || 0,
          });
        }
        return acc;
      }, []);

      return sendGameCSVData({
        opponent: opponent.trim(),
        title: title.trim(),
        date,
        stats,
      });
    },
    {
      onSuccess: async () => {
        toast({
          title: "Successfully Added Game",
          status: "success",
          duration: 2500,
          position: "bottom-left",
        });
        await queryClient.invalidateQueries([RECENT_OPPONENTS]);
      },
      onError: async () => {
        toast({
          title: `Failed to Upload Game`,
          status: "error",
          duration: 2500,
          position: "bottom-left",
        });
      },
      ...opts,
    }
  );
};

export default useAddGame;
